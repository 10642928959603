import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import vars from '../../utils/styles/vars';
import Button from '../../utils/common/Button';
import closeIcon from '../../assets/icons/close.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import media from '../../utils/helpers/media';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  animation-duration: 0.3s;
  animation-name: fadeIn;
`;

const Modal = styled.div`
  height: 432px;
  width: 952px;
  background: white;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 21px 0;
  animation-duration: 0.5s;
  animation-name: zoom;

  ${media.phone`
    height: 354px;
    width: 306px;
    text-align: center;
    padding-top: 50px;
;`}

  .text {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin-top: 25px;

    ${media.phone`
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      line-height: 15px;
      width: 200px;
;`}
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 300;
    font-family: 'Proxima Nova', sans-serif;
    margin-top: 60px;

    ${media.phone`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      margin-top: 0;
      width: 200px;
;`}
  }

  .nah {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: ${vars.colors.primaryColor};
    font-family: 'Proxima Nova', sans-serif;
    cursor: pointer;

    ${media.phone`
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      margin-top: 10px;
    ;`}
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
  }
`;

const Form = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 20px;

  ${media.phone`
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
    margin-bottom: 10px;
;`}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-right: 16px;
`;
const Label = styled.label`
  color: #818a97;
  margin-left: 5px;
  margin-bottom: 6px;
  display: inline-block;
  text-align: left;

  ${media.phone`
    font-size: 10px;
    margin-left: 15px;
;`}
`;

const StyledInput = styled.input`
  border: 1px solid #d9dce6;
  background: white;
  width: 312px;
  height: 56px;
  border-radius: 8px;
  transition: border 0.3s ease;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 12px 15px;

  ${media.phone`
    width: 230px;
    margin-bottom: 10px;
    height: 48px;
    margin-left: 15px;
;`}

  &:focus {
    outline: none;
    border: 1px solid ${vars.colors.primaryColor};
  }

  &:focus + ${Label} {
    color: ${vars.colors.primaryColor};
  }
`;

const NoButton = styled(Button)`
  background: rgba(0, 0, 0, 0);
  color: ${vars.colors.primaryColor};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${vars.colors.primaryColor};
  cursor: pointer;
  box-shadow: none;

  &:hover {
    background: none;
  }

  ${media.phone`
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
    ;`}
`;

const YesButton = styled(Button)`
  height: 56px;
  width: 232px;

  ${media.phone`
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    ;`}
`;

const MailInfoModal = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const modalRef = useRef();
  useOutsideClick(modalRef, () => closeModal());
  return (
    <Wrapper>
      <Modal ref={modalRef}>
        <h2>Скоро с Манго станет еще удобнее</h2>
        <p className="text">
          И квартиру, которую вы сдаете, можно будет застраховать в <br />
          Манго по специальным условиям
        </p>
        <Form>
          <InputContainer>
            <StyledInput
              type="email"
              id="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <Label htmlFor="email">E-mail</Label>
          </InputContainer>
          <YesButton text="сообщите мне" onClick={closeModal} />
        </Form>
        <NoButton text="Спасибо, не надо" onClick={closeModal} />
        <button type="button" onClick={closeModal} className="close-button">
          <img src={closeIcon} alt="#" aria-label="закрыть окно" />
        </button>
      </Modal>
    </Wrapper>
  );
};

MailInfoModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default MailInfoModal;
