import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import vars from '../../../utils/styles/vars';
import closeIconPng from '../../../assets/icons/close.png';
import Button from '../../../utils/common/Button';
import useLockBodyScroll from '../../../hooks/useLockBodyScroll';
import MailInfoModal from '../../Home/MailInfoModal';
import phoneNumIcon from '../../../assets/icons/phoneNum.png';

const links = [
  {
    id: 0,
    path: '/conditions',
    name: 'Условия и выплаты'
  },
  {
    id: 1,
    path: '/about',
    name: 'О нас'
  },
  {
    id: 2,
    path: '/landing',
    name: 'Я живу в своей квартире'
  }
];

const MobileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  animation-duration: 0.3s;
  animation-name: fadeIn;
  max-width: 576px;
  height: 100vh;
  -webkit-overflow-scrolling: touch;

  &::after {
    position: absolute;
    content: '';
    width: 80%;
    height: 1px;
    background: #eee;
    top: 38%;
    margin: 24px 0;

    @media (max-width: 415px) {
      top: 40%;
    }
    @media (max-width: 375px) {
      top: 41%;
    }
    @media (max-width: 320px) {
      top: 37%;
    }
    @media (max-height: 667px) {
      top: 39%;
    }
    @media (max-height: 568px) {
      top: 37%;
    }
  }

  span {
    color: ${vars.colors.primaryColor};
    text-decoration: underline;
    font-size: 18px;
    letter-spacing: 0.25px;
  }
`;

const MobileIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 5px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${vars.colors.primaryColor};
  letter-spacing: 0.012em;
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.75px;
  font-family: 'Proxima Nova', sans-serif;

  :nth-of-type(1) {
    order: 1;
  }
  :nth-of-type(2) {
    order: 2;
  }
  :nth-of-type(3) {
    order: 4;
  }

  &:focus,
  &:active {
    color: ${vars.colors.primaryColor};
  }
`;

const StyledButton = styled(Button)`
  height: 56px;
  width: 208px;
  transform: translate3d(0, 0, 0);
  letter-spacing: 1.2px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 35px;
  order: 7;
  margin-bottom: 40px;
`;

const StyledOutsideLink = styled.a`
  text-decoration: none;
  color: ${vars.colors.primaryColor};
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
  order: 3;
  width: 100px;
  height: 40px;
  background: #fff;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledLandLink = styled.a`
  text-decoration: none;
  color: ${vars.colors.primaryColor};
  letter-spacing: 0.012em;
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.75px;
  font-family: 'Proxima Nova', sans-serif;
  margin-bottom: 0;
  order: 6;
`;
const StyledRentLink = styled.a`
  text-decoration: none;
  color: ${vars.colors.primaryColor};
  letter-spacing: 0.012em;
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.75px;
  font-family: 'Proxima Nova', sans-serif;
  margin-bottom: 0;
  order: 7;
`;

const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  order: 8;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #818a97;
    text-decoration: none;
    font-family: 'Proxima Nova', sans-serif;
  }
`;

const MobileMenuModal = ({ closeMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useLockBodyScroll();
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const redirect = () => {
    window.location.assign(
      `https://customer.service.mango.rocks/offer/address`
    );
  };
  return (
    <MobileMenuWrapper>
      {links.map(link => (
        <StyledLink
          key={link.id}
          to={link.path}
          activeStyle={{ color: `${vars.colors.primaryColor}` }}
          onClick={() => closeMenu()}
        >
          {link.name}
        </StyledLink>
      ))}
      <StyledOutsideLink href="https://customer.service.mango.rocks/">
        Вход
      </StyledOutsideLink>
      <StyledRentLink href="#" onClick={openModal}>
        Я сдаю квартиру
      </StyledRentLink>
      <StyledLandLink href="#" onClick={openModal}>
        Я снимаю квартиру
      </StyledLandLink>
      <StyledButton text="Узнать цену" onClick={redirect} />
      <PhoneNumber>
        <img src={phoneNumIcon} alt="#" />
        <span>+7 (495) 134-29-24 </span>
      </PhoneNumber>
      <MobileIcon onClick={() => closeMenu()}>
        <img src={closeIconPng} alt="#" />
      </MobileIcon>
      {isModalOpen && <MailInfoModal closeModal={closeModal} />}
    </MobileMenuWrapper>
  );
};

MobileMenuModal.propTypes = {
  closeMenu: PropTypes.func.isRequired
};

export default MobileMenuModal;
