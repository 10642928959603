import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavLinks from './NavLinks';
import NavAction from './NavAction';
import mobileIcon from '../../assets/icons/mobile-menu.svg';
import useMedia from '../../hooks/useMedia';
import MobileMenuModal from './Mobile/MobileMenuModal';
import useOutsideClick from '../../hooks/useOutsideClick';

const MobileIcon = styled.div`
  position: absolute;
  right: 7%;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

const StyledNavbar = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
`;

const Navbar = ({
  openMobileMenu,
  closeMobileMenu,
  isMobileMenu,
  handleBlur,
  handleNavbar,
  isNavbarOpen
}) => {
  const [isActionVisible, setActionVisible] = useState(false);
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');
  const formRef = useRef();
  useOutsideClick(formRef, () => handleBlur());

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset;
    if (small) {
      // показываем кнопку в хедере после пролистания кнопки на главной странице в адаптиве
      if (scrollTop > 615) {
        setActionVisible(true);
      } else {
        setActionVisible(false);
      }
      // показываем кнопку в хедере после пролистания кнопки на главной странице в вэбе
    } else if (scrollTop > 697) {
      setActionVisible(true);
    } else {
      setActionVisible(false);
    }
  }, [small]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {large && (
        <StyledNavbar ref={formRef}>
          {isNavbarOpen && <NavLinks isActionVisible={isActionVisible} />}
          {isActionVisible && (
            <NavAction
              handleNavbar={handleNavbar}
              isNavbarOpen={isNavbarOpen}
              handleBlur={handleBlur}
            />
          )}
        </StyledNavbar>
      )}
      {small && isActionVisible && (
        <>
          <NavAction handleNavbar={handleNavbar} isNavbarOpen={isNavbarOpen} />
        </>
      )}
      {small && (
        <MobileIcon onClick={openMobileMenu}>
          <img src={mobileIcon} alt="#" />
        </MobileIcon>
      )}
      {isMobileMenu && <MobileMenuModal closeMenu={closeMobileMenu} />}
    </>
  );
};

Navbar.propTypes = {
  openMobileMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  isMobileMenu: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleNavbar: PropTypes.func.isRequired,
  isNavbarOpen: PropTypes.bool.isRequired
};

export default Navbar;
