import { createGlobalStyle } from 'styled-components';
import vars from './vars';
import '../../assets/fonts/fonts.css';
import '../keyframes/keyframes.css';

const GlobalStyle = createGlobalStyle`

  body {
    font-family: 'Gilroy', sans-serif;
    color: ${vars.colors.textColor};
    background: #fff;
    box-sizing: border-box;
    margin: 0 auto;
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  &.modal-open{
    overflow: hidden;
  }
`;

export default GlobalStyle;
