import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Navbar from './Navbar/Navbar';
import vars from '../utils/styles/vars';
import logo from '../assets/icons/logo-new.png';
import logoWithoutWords from '../assets/icons/small-logo.svg';
import useMedia from '../hooks/useMedia';
import media from '../utils/helpers/media';

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${vars.colors.separator};
  position: sticky;
  top: 0;
  height: 77px;
  z-index: 999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  max-width: 1440px;
  margin: 0 auto;
  transition: all 0.3s ease;

  ${media.phone`
    justify-content: space-between;
    padding: 0 10px;
  ;`}
`;

const Logo = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 20px;

  .long-logo {
    width: 125px;
    height: 30px;
  }
`;

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isShortLogo, setShortLogo] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);
  const small = useMedia('(max-width: 576px)');
  const large = useMedia('(min-width: 577px)');

  const handleShowShortMenu = useCallback(() => {
    const scrollTop = window.pageYOffset;
    if (small) {
      if (scrollTop > 615) {
        setShortLogo(true);
      } else {
        setShortLogo(false);
      }
    } else if (scrollTop > 50) {
      setShortLogo(true);
    } else {
      setShortLogo(false);
      setIsNavbarOpen(true);
    }
  }, [small]);

  const handleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleBlur = () => {
    setIsNavbarOpen(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleShowShortMenu);
    return () => {
      window.removeEventListener('scroll', handleShowShortMenu);
    };
  }, [handleShowShortMenu]);

  const openMobileMenu = () => {
    setMobileMenu(true);
  };
  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <HeaderContainer>
      <StyledHeader>
        <Link to="/">
          {small && !isMobileMenu && (
            <Logo>
              {isShortLogo ? (
                <img
                  src={logoWithoutWords}
                  alt="Лого компании"
                  className="short-logo"
                />
              ) : (
                <img src={logo} alt="Лого компании" className="long-logo" />
              )}
            </Logo>
          )}
          {large && (
            <Logo>
              <img src={logo} alt="Лого компании" className="long-logo" />
            </Logo>
          )}
        </Link>
        <Navbar
          openMobileMenu={openMobileMenu}
          closeMobileMenu={closeMobileMenu}
          isMobileMenu={isMobileMenu}
          handleBlur={handleBlur}
          handleNavbar={handleNavbar}
          isNavbarOpen={isNavbarOpen}
          onBlur={handleBlur}
        />
      </StyledHeader>
    </HeaderContainer>
  );
};

export default Header;
