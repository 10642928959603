import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import GlobalStyle from '../utils/styles/GlobalStyle';

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
