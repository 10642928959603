const vars = {
  colors: {
    primaryColor: `#794CFA`,
    separator: `#EEEEEE`,
    textColor: `#2F3A5B`,
    mainGrey: `#94919C`,
    regentGray: `#818A97`
  },
  boxShadow: `0px 6px 8px rgba(121, 76, 250, 0.24);`
};

export default vars;
