import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import vars from '../../utils/styles/vars';
import Button from '../../utils/common/Button';
import phoneNumIcon from '../../assets/icons/phoneNum.png';

const links = [
  {
    id: 0,
    path: '/conditions',
    name: 'Условия и выплаты',
    href: '#'
  },
  {
    id: 1,
    path: '/about',
    name: 'О нас',
    href: '#'
  }
];

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: initial;
  color: #94919c;
  margin-right: 20px;
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 24px;
  color: #34353c;

  &:hover {
    color: #5224d6;
  }
  &:focus,
  &:active {
    color: ${vars.colors.primaryColor};
  }
`;
const StyledOutsideLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 34px;
  transition: all 0.3s ease;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${vars.colors.primaryColor};
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #5224d6;
  }
  &:focus,
  &:active {
    color: ${vars.colors.primaryColor};
  }
`;

const StyledButton = styled(Button)`
  z-index: 50;
  margin-right: 10px;
  height: 40px;
  width: 147px;
  transform: translate3d(0, 0, 0);
  line-height: 28px;
  letter-spacing: 1.2px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-left: 12px;
  margin-right: 8px;
`;

const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #818a97;
    font-family: 'Proxima Nova', sans-serif;
  }
`;

const NavLinks = ({ isActionVisible }) => {
  const redirectToCalc = () => {
    window.location.assign(
      `https://customer.service.mango.rocks/offer/address`
    );
  };
  return (
    <>
      {links.map(link => (
        <StyledLink
          key={link.id}
          to={link.path}
          activeStyle={{ color: `${vars.colors.primaryColor}` }}
          href={link.href}
        >
          {link.name}
        </StyledLink>
      ))}
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {isActionVisible && (
          <StyledButton text="Узнать цену" onClick={redirectToCalc} />
        )}
      </ReactCSSTransitionGroup>
      <StyledOutsideLink href="https://customer.service.mango.rocks/">
        Вход
      </StyledOutsideLink>
      <PhoneNumber>
        <img src={phoneNumIcon} alt="#" />
        <span>+7 (495) 134-29-24 </span>
      </PhoneNumber>
    </>
  );
};

NavLinks.propTypes = {
  isActionVisible: PropTypes.bool.isRequired
};

export default NavLinks;
