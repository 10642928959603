import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import vars from '../utils/styles/vars';
import facebookIcon from '../assets/icons/facebook.png';
import instaIcon from '../assets/icons/instagram.png';
import vkIcon from '../assets/icons/vk.png';
import media from '../utils/helpers/media';
import closeIcon from '../assets/icons/close-white.png';
import useMedia from '../hooks/useMedia';

const FooterContainer = styled.div`
  background: #2f3a5b;
  box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24);
  color: white;
`;

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${vars.colors.separator};
  padding: 30px 50px 50px 50px;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Proxima Nova', sans-serif;
  height: 504px;

  ${media.phone`
    padding: 50px 30px;
    height: unset;
  ;`}

  .pages,
  .rights {
    margin-right: 77px;

    ${media.phone`
      margin-right: 0;
      
  ;`}
  }

  .rights {
    margin-bottom: 45px;
    line-height: 31px;
    font-size: 16px;

    ${media.phone`
      font-size: 10px;
      line-height: 16px;
  ;`}
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-align: left;
  width: 100%;

  ${media.phone`
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  ;`}
`;
const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  margin-top: 37px;
  font-size: 16px;
  line-height: 24px;

  ${media.phone`
    margin: 0;
  ;`}
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  a {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.25px;
    margin-top: 14px;

    ${media.phone`
      margin: 0;
      margin-bottom:12px;
    ;`}

    &:last-of-type {
      margin-top: 14px;
      margin-bottom: 37px;

      ${media.phone`
        margin: 0;
        margin-bottom: 36px;
      `};
    }
  }

  .link {
    ${media.phone`
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    `};
  }

  span {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.25px;
    margin-top: 14px;

    &:last-of-type {
      margin-top: 14px;
    }

    ${media.phone`
    font-size: 10px;
    `};
  }
`;

const SnsWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px;

  ${media.phone`
    margin: 35px 0 30px 0;
  ;`}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: 20px;
    height: 20px;
    color: #2f3a5b;
  }
`;

const GeneralInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0 40px 0;

  ${media.phone`
      flex-direction: column;   
      margin:0;
      align-items: start;
  ;`}

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin-bottom: 10px;

    ${media.phone`
      font-size: 10px;
      line-height: 16px;
=  `};
  }
`;

const Cookies = styled.div`
  width: 1024px;
  height: 79px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 11px 23px;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.67);

    ${media.phone`
      font-size: 10px;
      line-height: 16px;
  ;`}
  }

  .close-icon {
    position: absolute;
    top: 25%;
    right: 1%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    img {
      width: 100%;
    }
    &:focus {
      outline: none;
    }

    ${media.phone`
      position: absolute;
      top: 0;
      right: 0;
  ;`}
  }

  ${media.phone`
    align-items: start;
    width: 100%;
    height: 100%;
    position: relative;
    padding-right: 30px;
  ;`}
`;

const footer = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cookiesIsShown, setCookiesIsShown] = useState(true);

  const handleCloseCookies = () => {
    setCookiesIsShown(false);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const small = useMedia('(max-width: 576px)');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const large = useMedia('(min-width: 577px)');
  return (
    <>
      {large && (
        <FooterContainer>
          <StyledFooter>
            <InfoWrapper>
              <PolicyWrapper>
                <p className="rights">
                  ООО «Манго» — зарегистрированный товарный знак <br />
                  © 2019, ООО «Mango Techsurance LLC». <br />
                  Генеральная лицензия Банка России № 2209 от 24.11.2019
                </p>
                <GeneralInfo>
                  <p>
                    Политика конфиденциальности <br />
                    Пользовательское соглашение
                  </p>
                </GeneralInfo>
              </PolicyWrapper>
              <InfoItem className="pages">
                <Link to="/conditions">
                  <span>Условия и выплаты</span>
                </Link>
                <Link to="/about">
                  <span>О компании</span>
                </Link>
                <Link to="/about">
                  <span>Вакансии в Манго</span>
                </Link>
                <SnsWrapper>
                  <IconContainer>
                    <img src={instaIcon} alt="instagram" />
                  </IconContainer>
                  <IconContainer>
                    <img src={vkIcon} alt="instagram" />
                  </IconContainer>
                  <IconContainer>
                    <img src={facebookIcon} alt="instagram" />
                  </IconContainer>
                </SnsWrapper>
              </InfoItem>
              <InfoItem>
                <span>info@mango.rocks</span>
                <span>+7 (495) 134-29-24 </span>
                <span>Москва, Садовническая набережная, 9 </span>
              </InfoItem>
            </InfoWrapper>
            {cookiesIsShown && (
              <Cookies>
                <p>
                  Сайт «Манго» использует файлы cookie с целью персонализации
                  сервисов и повышения удобства пользования веб-сайтом. Cookie{' '}
                  <br />
                  представляют собой небольшие файлы, содержащие информацию о
                  предыдущих посещениях веб-сайта. Если вы не хотите
                  использовать <br />
                  файлы cookie, измените настройки браузера.
                </p>
                <button
                  type="button"
                  onClick={handleCloseCookies}
                  className="close-icon"
                >
                  <img
                    src={closeIcon}
                    alt="close cokkies"
                    area-label="close cokkies"
                  />
                </button>
              </Cookies>
            )}
          </StyledFooter>
        </FooterContainer>
      )}

      {small && (
        <FooterContainer>
          <StyledFooter>
            <InfoWrapper>
              <InfoItem className="pages">
                <Link to="/conditions">
                  <span className="link">Условия и выплаты</span>
                </Link>
                <Link to="/about">
                  <span className="link">О компании</span>
                </Link>
                <Link to="/about">
                  <span className="link">Вакансии в Манго</span>
                </Link>
              </InfoItem>
              <InfoItem>
                <span className="link">info@mango.rocks</span>
                <span className="link">+7 (495) 134-29-24 </span>
                <span className="link">
                  Москва, Садовническая набережная, 9{' '}
                </span>
              </InfoItem>
              <SnsWrapper>
                <IconContainer>
                  <img src={instaIcon} alt="instagram" />
                </IconContainer>
                <IconContainer>
                  <img src={vkIcon} alt="instagram" />
                </IconContainer>
                <IconContainer>
                  <img src={facebookIcon} alt="instagram" />
                </IconContainer>
              </SnsWrapper>
              <PolicyWrapper>
                <GeneralInfo>
                  <p>
                    Политика конфиденциальности <br />
                    Пользовательское соглашение
                  </p>
                  <p className="rights">
                    ООО «Манго» — зарегистрированный товарный знак <br />
                    © 2019, ООО «Mango Techsurance LLC». <br />
                    Генеральная лицензия Банка России № 2209 от 24.11.2019
                  </p>
                </GeneralInfo>
              </PolicyWrapper>
            </InfoWrapper>
            {cookiesIsShown && (
              <Cookies>
                <p>
                  Сайт «Манго» использует файлы cookie с целью персонализации
                  сервисов и повышения удобства пользования веб-сайтом. Cookie{' '}
                  <br />
                  представляют собой небольшие файлы, содержащие информацию о
                  предыдущих посещениях веб-сайта. Если вы не хотите
                  использовать <br />
                  файлы cookie, измените настройки браузера.
                </p>
                <button
                  type="button"
                  className="close-icon"
                  onClick={handleCloseCookies}
                >
                  <img
                    src={closeIcon}
                    alt="close-icon"
                    area-label="close icon"
                  />
                </button>
              </Cookies>
            )}
          </StyledFooter>
        </FooterContainer>
      )}
    </>
  );
};

export default footer;
