import React from 'react';
import styled from 'styled-components';
import Button from '../../utils/common/Button';
import media from '../../utils/helpers/media';
import useMedia from '../../hooks/useMedia';

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  animation-duration: 0.75s;
  animation-name: fadeIn;

  ${media.phone`
    flex-direction: row-reverse;
    width: 100%;
  ;`}
`;

const StyledButton = styled(Button)`
  z-index: 50;
  margin-right: 10px;
  height: 40px;
  width: 147px;
  transform: translate3d(0, 0, 0);
  line-height: 28px;
  letter-spacing: 1.2px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.75px;
  text-transform: uppercase;

  ${media.phone`
    width: 136px;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    text-transform: uppercase;
    margin-right: 54px;
  `};
  @media (max-width: 320px) {
    margin-right: 41px;
  }
`;

const NavAction = () => {
  const small = useMedia('(max-width: 576px)');
  const handleFocus = () => {
    window.location.assign(
      `https://customer.service.mango.rocks/offer/address`
    );
  };
  return (
    <ActionWrapper>
      {small && <StyledButton text="узнать Цену" onClick={handleFocus} />}
    </ActionWrapper>
  );
};

export default NavAction;
