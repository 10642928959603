import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '../styles/vars';

const MainButton = styled.button`
  padding: 0 26px;
  display: block;
  height: 40px;
  background: ${vars.colors.primaryColor};
  text-align: center;
  font-family: ${props => (props.form ? 'Avenir Next Cyr' : 'Gilroy')},
    sans-serif;
  font-size: ${props => (props.form ? '14px' : '17px')};
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: white;
  box-shadow: ${vars.boxShadow};
  border-radius: 8px;
  font-weight: ${props => (props.form ? '500' : 'normal')};
  letter-spacing: ${props => (props.form ? '0.75px' : '1.2px')};
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    background: #5224d6;
  }

  &:focus {
    outline: none;
  }
`;

const Button = ({ text, ...props }) => (
  <MainButton {...props} type="button">
    {text}
  </MainButton>
);

Button.propTypes = {
  text: PropTypes.string.isRequired
};

export default Button;
